<template>
    <div class="flex flex-row justify-between bg-base-200 h-6 shrink-0">

        <!-- window title -->
        <div class="desktop-drag-zone grow"/>

        <!-- window control buttons -->
        <div class="flex flex-row pr-2">
            <SButton 
                size="xs"
                ghost
                @click="minimize()"
            >
                <MinusIcon class="w-6 h-6"/>
            </SButton>
            <SButton
                size="xs"
                ghost
                @click="toggleMaximize()"
            >
                <StopIcon class="w-6 h-6"/>
            </SButton>
            <SButton
                size="xs"
                ghost
                @click="close()"
            >
                <XMarkIcon class="w-6 h-6"/>
            </SButton>
        </div>
    </div>
</template>

<script setup>
import { MinusIcon, StopIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import SButton from '@/components/controls/buttons/SButton.vue';


function minimize() {
    window.ipcRenderer.send('window-minimize');
}

function toggleMaximize() {
    window.ipcRenderer.send('window-maximize');
}

function close () {
    window.ipcRenderer.send('window-close');
}

</script>

<style>
    .desktop-drag-zone{
        -webkit-app-region: drag;
    }
</style>