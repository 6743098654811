<template>
	<div class="flex h-full soloveyko-text">
		<svg class="flex my-auto "
			version="1.1"
			id="_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="36 0 115 56"
			xml:space="preserve"
			height="56"
			width="115"
			>
			<g>
				<path class="st0" d="M48.6,37.94c-1.08,0-2.08-0.21-3.02-0.61s-1.75-0.97-2.45-1.69c-0.69-0.72-1.24-1.57-1.63-2.54
					s-0.58-2.01-0.58-3.15c0-1.12,0.19-2.15,0.58-3.12c0.39-0.97,0.94-1.82,1.64-2.54c0.69-0.72,1.51-1.29,2.45-1.7
					c0.92-0.41,1.92-0.62,3.02-0.62c1.02,0,1.91,0.13,2.64,0.39c0.73,0.27,1.47,0.71,2.22,1.32c0.11,0.08,0.19,0.17,0.25,0.25
					s0.1,0.17,0.12,0.27c0.02,0.08,0.02,0.19,0.02,0.32c0,0.25-0.08,0.46-0.27,0.62c-0.18,0.17-0.39,0.27-0.65,0.28
					c-0.24,0.01-0.49-0.07-0.73-0.25c-0.49-0.43-1-0.75-1.52-0.99c-0.52-0.23-1.21-0.35-2.08-0.35c-0.78,0-1.52,0.16-2.22,0.48
					c-0.68,0.33-1.28,0.75-1.81,1.31c-0.52,0.56-0.94,1.21-1.22,1.94c-0.29,0.74-0.44,1.52-0.44,2.36c0,0.85,0.15,1.64,0.44,2.39
					c0.3,0.74,0.69,1.39,1.22,1.94c0.52,0.56,1.13,1,1.82,1.3c0.69,0.32,1.42,0.46,2.22,0.46c0.68,0,1.32-0.12,1.92-0.35
					s1.18-0.55,1.74-0.97c0.24-0.17,0.48-0.25,0.71-0.23s0.43,0.11,0.58,0.28c0.16,0.17,0.24,0.39,0.24,0.68
					c0,0.13-0.02,0.27-0.08,0.38c-0.05,0.13-0.13,0.24-0.24,0.35c-0.72,0.57-1.49,1.01-2.3,1.29
					C50.37,37.77,49.51,37.94,48.6,37.94z"/>
			</g>
			<g>
				<path class="st0" d="M60.57,37.81c-1.08,0-2.02-0.24-2.85-0.71c-0.83-0.46-1.47-1.13-1.94-1.95c-0.46-0.83-0.71-1.79-0.71-2.86
					c0-1.09,0.24-2.05,0.71-2.87c0.47-0.82,1.12-1.49,1.94-1.95c0.83-0.46,1.78-0.71,2.85-0.71s2,0.24,2.82,0.71
					s1.47,1.13,1.94,1.95c0.46,0.83,0.71,1.79,0.71,2.87c0,1.08-0.23,2.02-0.69,2.86c-0.46,0.83-1.12,1.49-1.92,1.95
					C62.59,37.58,61.65,37.81,60.57,37.81z M60.57,36.02c0.69,0,1.3-0.16,1.84-0.48c0.52-0.33,0.95-0.75,1.24-1.32
					c0.3-0.57,0.44-1.19,0.44-1.92s-0.15-1.38-0.44-1.94c-0.3-0.56-0.72-1.01-1.24-1.33c-0.52-0.33-1.14-0.48-1.84-0.48
					c-0.69,0-1.3,0.16-1.84,0.48c-0.52,0.33-0.95,0.75-1.25,1.33c-0.3,0.56-0.45,1.21-0.45,1.94s0.16,1.38,0.45,1.92
					c0.32,0.56,0.72,1,1.25,1.32C59.26,35.87,59.87,36.02,60.57,36.02z"/>
			</g>
			<g>
				<path class="st0" d="M72.65,26.86c0.21,0,0.38,0.05,0.54,0.16s0.29,0.28,0.38,0.5l4.11,8.9c0.12,0.27,0.13,0.5,0.04,0.71
					s-0.25,0.36-0.48,0.46c-0.29,0.13-0.54,0.17-0.75,0.1c-0.22-0.07-0.39-0.23-0.54-0.5l-3.75-8.51h1.02l-3.87,8.51
					c-0.12,0.25-0.3,0.41-0.52,0.49c-0.23,0.07-0.49,0.05-0.77-0.08c-0.25-0.12-0.43-0.28-0.52-0.48
					c-0.09-0.21-0.08-0.43,0.05-0.69l4.13-8.9C71.9,27.07,72.23,26.86,72.65,26.86z"/>
			</g>
			<g>
				<path class="st0" d="M84.7,37.81c-1.08,0-2.02-0.24-2.85-0.71c-0.83-0.46-1.47-1.13-1.94-1.95c-0.46-0.83-0.71-1.79-0.71-2.86
					c0-1.09,0.24-2.05,0.71-2.87s1.12-1.49,1.94-1.95c0.83-0.46,1.78-0.71,2.85-0.71s2,0.24,2.82,0.71
					c0.83,0.46,1.47,1.13,1.94,1.95c0.46,0.83,0.71,1.79,0.71,2.87c0,1.08-0.23,2.02-0.69,2.86c-0.46,0.83-1.12,1.49-1.92,1.95
					C86.73,37.58,85.79,37.81,84.7,37.81z M84.7,36.02c0.69,0,1.3-0.16,1.84-0.48c0.52-0.33,0.95-0.75,1.24-1.32
					c0.3-0.56,0.44-1.19,0.44-1.92s-0.15-1.38-0.44-1.94c-0.3-0.56-0.72-1.01-1.24-1.33c-0.52-0.33-1.14-0.48-1.84-0.48
					c-0.69,0-1.3,0.16-1.84,0.48c-0.52,0.33-0.95,0.75-1.25,1.33c-0.3,0.56-0.45,1.21-0.45,1.94s0.16,1.38,0.45,1.92
					c0.32,0.56,0.72,1,1.25,1.32C83.4,35.87,84.01,36.02,84.7,36.02z"/>
			</g>
			<g>
				<path class="st0" d="M93.73,37.73c-0.29,0-0.54-0.1-0.73-0.29c-0.19-0.19-0.29-0.43-0.29-0.73v-8.89
					c0.02-0.29,0.13-0.52,0.33-0.72c0.18-0.19,0.41-0.28,0.69-0.28c0.3,0,0.55,0.1,0.73,0.28c0.18,0.19,0.28,0.43,0.28,0.74v3.43
					h3.87v0.39c0.73,0,1.38,0.13,1.94,0.38c0.56,0.25,1.01,0.6,1.33,1.05s0.48,0.96,0.48,1.55c0,0.6-0.16,1.13-0.48,1.59
					s-0.75,0.83-1.33,1.09c-0.56,0.27-1.21,0.39-1.94,0.39h-4.88C93.73,37.71,93.73,37.73,93.73,37.73z M97.03,32.35v-1.08h1.9
					c0.34,0,0.6-0.13,0.82-0.38c0.22-0.27,0.33-0.58,0.33-0.99c0-0.35-0.13-0.63-0.38-0.86c-0.25-0.23-0.57-0.34-0.96-0.34h-4.99
					v-1.87h4.99c0.62,0,1.17,0.12,1.67,0.38c0.48,0.25,0.86,0.58,1.15,1.02c0.28,0.43,0.41,0.92,0.41,1.49
					c0,0.77-0.29,1.4-0.86,1.91c-0.57,0.5-1.29,0.74-2.15,0.74h-1.92v-0.02H97.03z M94.74,35.84h3.87c0.52,0,0.96-0.13,1.29-0.38
					c0.34-0.27,0.5-0.6,0.5-1.01c0-0.44-0.17-0.8-0.5-1.08c-0.34-0.28-0.77-0.41-1.29-0.41h-3.87V35.84z"/>
			</g>
			<g>
				<path class="st0" d="M109.47,37.81c-1.11,0-2.08-0.24-2.93-0.71c-0.86-0.46-1.52-1.13-2.01-1.95s-0.73-1.79-0.73-2.86
					c0-1.09,0.23-2.05,0.68-2.87s1.09-1.49,1.91-1.95c0.82-0.46,1.74-0.71,2.79-0.71c1.03,0,1.92,0.23,2.68,0.68
					c0.74,0.45,1.3,1.09,1.7,1.9c0.39,0.81,0.58,1.73,0.58,2.78c0,0.25-0.08,0.46-0.27,0.62c-0.17,0.17-0.39,0.25-0.66,0.25h-8.03
					V31.4h7.97l-0.82,0.56c-0.01-0.66-0.15-1.25-0.39-1.79c-0.25-0.52-0.61-0.94-1.08-1.24c-0.46-0.3-1.03-0.45-1.69-0.45
					c-0.75,0-1.4,0.17-1.94,0.5c-0.54,0.34-0.95,0.79-1.22,1.36c-0.28,0.57-0.41,1.23-0.41,1.96c0,0.73,0.17,1.38,0.5,1.95
					c0.34,0.57,0.79,1.02,1.38,1.35c0.58,0.34,1.25,0.5,2.01,0.5c0.4,0,0.84-0.07,1.27-0.23s0.78-0.33,1.05-0.52
					c0.21-0.15,0.41-0.23,0.65-0.23c0.23-0.01,0.43,0.06,0.61,0.21c0.23,0.21,0.35,0.41,0.36,0.66c0.01,0.24-0.1,0.44-0.33,0.62
					c-0.45,0.36-1.01,0.65-1.68,0.88C110.72,37.7,110.08,37.81,109.47,37.81z"/>
			</g>
			<g>
				<path class="st0" d="M120.81,37.83c-0.92,0-1.74-0.19-2.46-0.58c-0.72-0.39-1.29-0.97-1.72-1.73
					c-0.41-0.75-0.62-1.69-0.62-2.79v-4.9c0-0.29,0.1-0.54,0.29-0.73s0.43-0.29,0.73-0.29s0.54,0.1,0.73,0.29s0.29,0.43,0.29,0.73
					v4.9c0,0.74,0.15,1.35,0.41,1.85c0.28,0.48,0.66,0.84,1.14,1.08c0.48,0.23,1.02,0.35,1.62,0.35c0.57,0,1.08-0.11,1.52-0.34
					c0.44-0.23,0.8-0.52,1.07-0.91c0.27-0.38,0.39-0.82,0.39-1.29h1.25c0,0.83-0.21,1.57-0.61,2.23s-0.96,1.18-1.65,1.57
					C122.48,37.64,121.69,37.83,120.81,37.83z M121.23,25.41c-0.68,0-1.29-0.12-1.84-0.35s-0.97-0.55-1.29-0.96
					c-0.33-0.41-0.47-0.87-0.47-1.37c0-0.28,0.08-0.51,0.27-0.69c0.18-0.18,0.38-0.28,0.63-0.28c0.27,0,0.48,0.1,0.65,0.28
					c0.17,0.19,0.25,0.41,0.25,0.69c0,0.34,0.17,0.61,0.51,0.85c0.35,0.23,0.78,0.35,1.29,0.35s0.95-0.12,1.29-0.35
					c0.35-0.23,0.51-0.51,0.51-0.85c0-0.28,0.08-0.51,0.27-0.69c0.18-0.18,0.38-0.28,0.63-0.28c0.27,0,0.48,0.1,0.65,0.28
					c0.17,0.19,0.25,0.41,0.25,0.69c0,0.5-0.16,0.96-0.48,1.36c-0.33,0.4-0.74,0.72-1.28,0.96
					C122.53,25.29,121.91,25.41,121.23,25.41z M125.21,37.73c-0.29,0-0.54-0.1-0.73-0.29c-0.19-0.19-0.29-0.43-0.29-0.73v-8.89
					c0-0.3,0.1-0.55,0.29-0.74c0.19-0.18,0.43-0.28,0.73-0.28s0.55,0.1,0.73,0.28c0.18,0.19,0.28,0.43,0.28,0.74v8.89
					c0,0.29-0.1,0.54-0.28,0.73C125.76,37.64,125.51,37.73,125.21,37.73z"/>
			</g>
			<g>
				<path class="st0" d="M130.27,37.75c-0.29,0-0.52-0.1-0.69-0.29c-0.17-0.19-0.27-0.43-0.27-0.73v-8.89
					c0-0.3,0.08-0.55,0.27-0.74c0.17-0.19,0.4-0.28,0.69-0.28c0.3,0,0.55,0.1,0.74,0.28c0.19,0.19,0.29,0.43,0.29,0.74v3.33h2.56
					v1.87h-2.56v3.69c0,0.29-0.1,0.54-0.29,0.73C130.82,37.67,130.58,37.75,130.27,37.75z M138.16,37.5
					c-0.17,0.16-0.41,0.24-0.73,0.23c-0.32-0.01-0.55-0.1-0.73-0.27l-4.84-5.2l4.56-5.1c0.17-0.18,0.41-0.29,0.73-0.33
					c0.32-0.02,0.55,0.04,0.73,0.21c0.21,0.19,0.32,0.39,0.34,0.62c0.02,0.23-0.05,0.44-0.22,0.62l-3.6,3.87l3.82,4.11
					c0.17,0.17,0.27,0.38,0.27,0.61S138.38,37.31,138.16,37.5z"/>
			</g>
			<g>
				<path class="st0" d="M144.41,37.81c-1.08,0-2.02-0.24-2.85-0.71c-0.83-0.46-1.47-1.13-1.94-1.95
					c-0.46-0.83-0.71-1.79-0.71-2.86c0-1.09,0.24-2.05,0.71-2.87s1.12-1.49,1.94-1.95c0.83-0.46,1.78-0.71,2.85-0.71
					s2,0.24,2.82,0.71s1.47,1.13,1.94,1.95c0.46,0.83,0.71,1.79,0.71,2.87c0,1.08-0.23,2.02-0.69,2.86
					c-0.46,0.83-1.12,1.49-1.92,1.95C146.44,37.58,145.49,37.81,144.41,37.81z M144.41,36.02c0.69,0,1.3-0.16,1.84-0.48
					c0.52-0.33,0.95-0.75,1.24-1.32c0.3-0.56,0.44-1.19,0.44-1.92s-0.15-1.38-0.44-1.94s-0.72-1.01-1.24-1.33
					c-0.52-0.33-1.14-0.48-1.84-0.48s-1.3,0.16-1.84,0.48c-0.52,0.33-0.95,0.75-1.25,1.33c-0.3,0.56-0.45,1.21-0.45,1.94
					s0.16,1.38,0.45,1.92c0.32,0.56,0.72,1,1.25,1.32C143.1,35.87,143.72,36.02,144.41,36.02z"/>
			</g>

		</svg>
	</div>
</template>  
<style scoped lang="scss">
[data-theme="light"] {
	.soloveyko-text {
		.st0{
			fill:#495468;
		}
	}
}
[data-theme="dark"] {
	.soloveyko-text {
		.st0{
			fill:#A7ADB9;
		}
	}
}

</style>


