import { defineStore } from "pinia";

export const useNavBarStore = defineStore('navBarStore', {
	state: () => ({
		menuCollapsed: false,
		mobileMenuOpen: false,
		wideContentView: true,
		modalIsOpen: false

	}),

	actions: {

		toggleDesktopSideBar() {
			this.menuCollapsed = !this.menuCollapsed;
			this.setMenuValueToStorage();

		},

		changeContentWidth() {
			this.wideContentView = !this.wideContentView;
			this.setContentWidthToStorage();

		},

		emitOpenModal() {
			this.modalIsOpen = true;
		},
		emitCloseModal() {
			this.modalIsOpen = false;
		},

		showMobileMenu() {
			this.mobileMenuOpen = true;
		},
		hideMobileMenu() {
			this.mobileMenuOpen = false;
		},
		setMenuValueToStorage () {
			localStorage.setItem('isMenuCollapsed', this.menuCollapsed ? 'true' : 'false' )
		},
		setStartMenuValue() {
			const localStorageValue = localStorage.getItem('isMenuCollapsed');
			if(localStorageValue) {
				this.menuCollapsed = localStorageValue === 'true';
			}
		},
		setContentWidthToStorage () {
			localStorage.setItem('isContentWide', this.wideContentView ? 'true' : 'false' )
		},

		setStartContentWidthValue() {
			const localStorageValue = localStorage.getItem('isContentWide');
			if(localStorageValue) {
				this.wideContentView = localStorageValue === 'true';
			}
		}
	}
})