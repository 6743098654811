<template>
    <!-- Navbar header -->
    <div class="flex flex-col rounded-b-lg lg:s-navbar-bg " :class="{ 'lg:px-0 ': navBarStore.menuCollapsed }">

		<!-- Soloveyko Logo -->
		<div class="flex h-16 shrink-0 items-center justify-center px-4"
            :class="{'lg:justify-center': navBarStore.menuCollapsed }">
			<div class="flex w-12 shrink-0 h-full items-center content-center">
				<LoveUaLoader />
			</div>
			<SoloveykoText
                :class="{'lg:hidden' : navBarStore.menuCollapsed}"
            />
		</div>

        <!-- Organisation Switcher -->
        <div class="flex w-full px-4  " :class="{ 'lg:px-4': !navBarStore.menuCollapsed }">
            <div class="flex w-full h-12 items-center">

                <div class="flex w-full h-full items-center">
                    <component class="w-full" :is="SChangeAccount" size="sm" />
                </div>
            </div>
        </div>

        <!-- Current User Info -->
        <div class="flex w-full px-4 border-b s-border pb-2 lg:border-none " :class="{ '': navBarStore.menuCollapsed }">
            <div class="flex h-12 w-full">

                <!-- Avatar -->
                <div class="flex items-center w-1/6" :class="{ 'lg:w-full lg:justify-center lg:hidden': navBarStore.menuCollapsed }">
                    <UserCircleIcon class="w-8 h-8" />
                </div>

                <!-- User name -->
                <SUserData class="w-4/6" :class="{ 'lg:hidden': navBarStore.menuCollapsed }"/>

                <!-- Settings -->
                <div class="flex items-center justify-center h-12 w-1/6" :class="{ 'lg:w-full': navBarStore.menuCollapsed }">
                    <SAccount @changeTheme="emitChangeTheme" />
                </div>
            </div>
        </div>


    </div>
</template>

<script setup>

import { onMounted, shallowRef, defineAsyncComponent } from 'vue';

import SAccount from "@/components/entities/SAccount.vue";

import SUserData from "@/components/entities/SUserData.vue";
import { UserCircleIcon } from "@heroicons/vue/24/outline";
import { useNavBarStore } from "@/store/navBarStore";

import LoveUaLoader from '@/components/stickers/LoveUaLoader.vue';
import SoloveykoText from '@/components/stickers/SoloveykoText.vue';


const emit = defineEmits(['change-theme']);

const emitChangeTheme = () => {
    emit('change-theme');
}

const navBarStore = useNavBarStore();

const SChangeAccount = shallowRef(null);

onMounted(async () => {
    SChangeAccount.value = defineAsyncComponent(() => import('@/components/reworked/entities/SChangeAccount.vue'));
})
</script>
<style scoped lang="scss">
.active,
.active:hover {
    background-color: rgb(102, 26, 230);
    color: #fff;
}
</style>