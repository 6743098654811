class ElectronHelper {
    isElectronApp () {
        if (navigator.userAgent.indexOf('Electron') >= 0) {
            return true;
        } else {
           
            return false;
        }
    }
}

export default new ElectronHelper();
