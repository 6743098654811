<!-- eslint-disable no-unused-vars -->
<template>
    <Teleport to="body">
        <div class="s-loader fixed inset-0 z-[9999] ease-in duration-300">
            <div id="loader-container">
                <component  :is="currentSvg"
                            id="loadingText"
                        />
            </div>
        </div>
    </Teleport>
</template>


<script setup>

import { onMounted, shallowRef } from 'vue';

import CoffeeLoader from '@/components/stickers/CoffeeLoader.vue';
import EnergyLoader from '@/components/stickers/EnergyLoader.vue';
import FireLoader from '@/components/stickers/FireLoader.vue';
import FlyingLoader from '@/components/stickers/FlyingLoader.vue';
import GptChatLoader from '@/components/stickers/GptChatLoader.vue';
import ImGoingLoader from '@/components/stickers/ImGoingLoader.vue';
import JusLoader from '@/components/stickers/JusLoader.vue';
import KinoLoader from '@/components/stickers/KinoLoader.vue';
import MagicLoader from '@/components/stickers/MagicLoader.vue';

const currentSvg = shallowRef(null)

const loadersImages = [
    CoffeeLoader,
    EnergyLoader,
    FireLoader,
    FlyingLoader,
    GptChatLoader,
    ImGoingLoader,
    JusLoader,
    KinoLoader,
    MagicLoader,
];

//TODO: create raddomizer helper

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
}

const getRandomSvgName = () => {
    const randomIndex = getRandomInt(0, loadersImages.length);
    return loadersImages[randomIndex];
}

onMounted(()=> {
    currentSvg.value = getRandomSvgName();
})


</script>
<style scoped>
.s-loader {
    background-color: rgba(17, 19, 24, 0.4);
}

#loader-container {
    width: 300px;
    height: 300px;
    color: #E4A600;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 3px solid #661AE6;
    border-radius: 50%;
    -webkit-animation: borderScale 1.5s infinite ease-in-out;
    animation: borderScale 1.5s infinite ease-in-out;
    }

#loadingText {
    font-weight: bold;
    font-size: 1.8em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-height: 75%;
    max-width: 75%;
}

@-webkit-keyframes borderScale {
    0% {
        border: 3px solid #E4A600;
    }
    50% {
        border: 15px solid #661AE6;
    }
    100% {
        border: 3px solid #E4A600;
    }
}

@keyframes borderScale {
    0% {
        border: 3px solid #E4A600;
    }
    50% {
        border: 15px solid #661AE6;
    }
    100% {
        border: 3px solid #E4A600;
    }
}
</style>