import { getActivePinia } from 'pinia';

export const resetPiniaStores = (exceptedStores=[]) => {
  // Exclude exceptedStores from active stores to reset
  let activeStores = Object.keys(getActivePinia().state.value);
  if (exceptedStores && exceptedStores.length) {
    activeStores = activeStores.filter(store => !exceptedStores.includes(store))
  }

  // Clear stores
  activeStores.forEach((store) => {
    getActivePinia()._s.get(store).$reset();
  });
}
