import { defineStore } from 'pinia'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    userSession: null
  }),

  getters: {
    accessToken() {
      if (this.userSession) {
        return this.userSession.accessToken.jwtToken
      }
    },
    idToken() {
      if (this.userSession) {
        return this.userSession.idToken.jwtToken
      }
    },
    refreshToken() {
      if (this.userSession) {
        return this.userSession.refreshToken.token
      }
    }
  },

  actions: {
  }
})