<template>
	<!-- NavBar Footer -->
	<div class="relative flex flex-col">

		<!-- Menu Collapse -->
		<div class="hidden lg:flex w-full h-12 shrink-0 overflow-hidden cursor-pointer">
			<div class="flex flex-row flex-nowrap h-12 relative rounded-t-lg w-full hover:s-navbar-item-hover hover:s-text-primary cursor-pointer "
				:class="{ 'justify-center': navBarStore.menuCollapsed }" @click="toggleDesktopSidebar">
				<div class="flex w-16 h-full justify-center items-center shrink-0">
					<WindowIcon class="w-6 h-6 -rotate-90" />
				</div>
				<div class="flex truncate items-center select-none"
					:class="{ 'lg:hidden': navBarStore.menuCollapsed, 'opacity': `${navBarStore.menuCollapsed ? 0 : 100}` }">
					{{ $t("navbar.collapse") }}
				</div>
			</div>
		</div>

		<!-- background -->
		<div class="absolute lg:flex hidden w-full h-full inset-0 s-navbar-bg z-[-1] rounded-t-lg" />

	</div>
</template>


<script setup>



import { WindowIcon } from "@heroicons/vue/24/outline";
import { useNavBarStore } from "@/store/navBarStore";

const navBarStore = useNavBarStore();

const toggleDesktopSidebar = () => {
  navBarStore.toggleDesktopSideBar();
}

</script>