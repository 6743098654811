<template>
  <div class="flex h-full w-full kino-loader">
    <svg class="flex m-auto max-h-full max-w-full"
          version="1.1"
          id="_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
          xml:space="preserve"
          height="500"
          width="500"
          >

  <g class="cls-12">
    <g id="kino">
      <path class="cls-4" d="m193.96,457.02c-3.98,0-7.91-1.35-11.06-3.8l-38.96-30.3c-5.77-4.49-8.09-11.8-6.44-18.51l-81.36-68.24-38.11-32.76-.11-.1c-8.53-7.99-12.31-13.69-12.63-19.06-.71-11.95,8.54-18.46,15.48-21.9-.02-.41-.03-.82-.03-1.23,0-13.75,11.19-24.93,24.93-24.93.97,0,1.93.06,2.89.17,3.37-7.97,11.27-13.58,20.45-13.58.8,0,1.64.05,2.54.16.17.01.34.03.53.05,12.55,1.56,24.37,14.72,36.87,28.65,1.53,1.7,3.08,3.43,4.63,5.13l60.27-71.86-.13-1.82c-.2-2.76.35-5.56,1.81-7.9,2.23-3.59,5.88-5.78,9.97-6.08l2.07-.15c11.4-10.91,25.35-18.28,40.69-21.5.32-.69.64-1.38.98-2.05,4.51-9.21,9.96-15.73,15.76-18.84,4.84-2.6,10.58-4.03,16.16-4.03,6.99,0,13.19,2.23,17.95,6.44,8.48,7.52,9.04,16.56,8.89,20.1-.12,2.8-.62,5.18-1.35,7.21,2.61,1.45,5.14,3.05,7.57,4.77l10.27-.76c.32-.02.64-.04.95-.04,6.13,0,11.36,4.41,12.53,10.3,2.71.12,5.5.42,8.33.89l34.23,5.73c2.46.41,4.54,1.75,5.89,3.64,2.53,1.86,4,4.96,3.63,8.34-.45,4.07-3.73,7.26-7.76,7.95l-13.51,2.31c9.58,1.69,16.99,10.25,16.45,20.87-.12,2.32-.67,4.61-1.62,6.74-2.79,6.25-8.23,10.49-14.72,11.68l-20,3.67c-2.43,16.33-9.52,31.37-20.69,43.75l-12.06,14.38c1.33.36,2.58.65,3.74.87,10.96,2.11,22.17,3.23,33.35,3.34l2.22-7.38c-.42-.64-.78-1.32-1.08-2.03h0c-1.39-.41-2.18-1.87-1.77-3.25l1.36-4.58,3.88-12.91c-.09-.42-.16-.84-.2-1.27l-1.14-12.41c-.42-4.63,3-8.74,7.62-9.17l135.5-12.45c.93-.09,1.84-.05,2.73.08,5.06.77,8.82,5.11,9.29,10.2l.82,8.92c.2,2.16-.15,4.37-1.21,6.27-1.77,3.17-4.84,5.15-8.32,5.47l-100.29,9.21,96.78,29.09c5.37,1.62,8.43,7.3,6.82,12.67l-3.96,13.19c-.95,3.14-3.36,5.49-6.36,6.38l-18.56,61.76c-2.23,7.43-9.21,12.62-16.96,12.62-1.72,0-3.44-.25-5.1-.75l-106.86-32.12c-2.8-.84-5.29-2.32-7.31-4.31-2.68.43-5.35.81-7.96,1.12-10.32,1.25-20.62,1.88-30.61,1.88-17.14,0-33.69-1.83-49.28-5.46l-31.76,37.86c-1.38,1.64-2.9,3.15-4.55,4.5l8.39,6.52c7.84,6.1,9.26,17.45,3.16,25.29l-.33.42c-3.43,4.42-8.62,6.95-14.23,6.95Z"/>
      <path class="cls-3" d="m195.93,449.25c-3.98,0-7.91-1.35-11.06-3.8l-38.96-30.3c-5.77-4.49-8.09-11.8-6.44-18.51l-81.36-68.24-38.11-32.76-.11-.1c-8.53-7.99-12.31-13.69-12.63-19.06-.71-11.95,8.54-18.46,15.48-21.9-.02-.41-.03-.82-.03-1.23,0-13.75,11.19-24.93,24.93-24.93.97,0,1.93.06,2.89.17,3.37-7.97,11.27-13.58,20.45-13.58.8,0,1.64.05,2.54.16.17.01.34.03.53.05,12.55,1.56,24.37,14.72,36.87,28.65,1.53,1.7,3.08,3.43,4.63,5.13l60.27-71.86-.13-1.74c-.25-3.38.67-6.79,2.82-9.41,2.24-2.74,5.44-4.4,8.96-4.66l2.07-.15c11.4-10.91,25.35-18.28,40.69-21.5.32-.69.64-1.38.98-2.05,4.51-9.21,9.96-15.73,15.76-18.84,4.84-2.6,10.58-4.03,16.16-4.03,6.99,0,13.19,2.23,17.95,6.44,8.48,7.52,9.04,16.56,8.89,20.1-.12,2.8-.62,5.18-1.35,7.21,2.61,1.45,5.14,3.05,7.57,4.77l10.27-.76c.32-.02.64-.04.95-.04,6.13,0,11.36,4.41,12.53,10.3,2.71.12,5.5.42,8.33.89l34.23,5.73c2.46.41,4.54,1.75,5.89,3.64,2.35,1.73,3.79,4.53,3.68,7.63-.15,4.35-3.54,7.92-7.84,8.66l-13.49,2.31c9.58,1.69,16.99,10.25,16.45,20.87-.12,2.32-.67,4.61-1.62,6.74-2.79,6.25-8.23,10.49-14.72,11.68l-20,3.67c-2.43,16.33-9.52,31.37-20.69,43.75l-12.06,14.38c1.33.36,2.58.65,3.74.87,10.96,2.11,22.17,3.23,33.35,3.34l2.22-7.38c-.42-.64-.78-1.32-1.08-2.03h0c-1.39-.41-2.18-1.87-1.77-3.25l1.36-4.58,3.88-12.91c-.09-.42-.16-.84-.2-1.27l-1.14-12.41c-.42-4.63,3-8.74,7.62-9.17l135.5-12.45c.64-.06,1.26-.06,1.88-.01,5.42.42,9.64,4.92,10.14,10.33l.81,8.8c.25,2.74-.41,5.52-2.08,7.71-1.82,2.38-4.48,3.85-7.45,4.13l-100.29,9.21,96.78,29.09c5.37,1.62,8.43,7.3,6.82,12.67l-3.96,13.19c-.95,3.14-3.36,5.49-6.36,6.38l-18.56,61.76c-2.23,7.43-9.21,12.62-16.96,12.62-1.72,0-3.44-.25-5.1-.75l-106.86-32.12c-2.8-.84-5.29-2.32-7.31-4.31-2.68.43-5.35.81-7.96,1.12-10.32,1.25-20.62,1.88-30.61,1.88-17.14,0-33.69-1.83-49.28-5.46l-31.76,37.86c-1.38,1.64-2.9,3.15-4.55,4.5l8.39,6.52c7.84,6.1,9.26,17.45,3.16,25.29l-.33.42c-3.43,4.42-8.62,6.95-14.23,6.95Z"/>
      <g id="kino-2" data-name="kino">
        <g>
          <g>
            <g class="cls-10">
              <path class="cls-8" d="m344.19,283.66l8.68-28.87,7.65,2.3c.48.14.84.58.93,1.15,1.55,9.62-1.6,20.1-8.2,27.27-.4.43-.93.59-1.41.45l-7.65-2.3Z"/>
            </g>
            <path class="cls-7" d="m342.56,282.04l8.68-28.87,7.65,2.3c.48.14.84.58.93,1.15,1.55,9.62-1.6,20.1-8.2,27.27-.4.43-.93.59-1.41.45l-7.65-2.3Z"/>
          </g>
          <g>
            <g class="cls-10">
              <path class="cls-8" d="m440.81,401.4l-106.86-32.12c-5.67-1.7-8.88-7.68-7.17-13.34l20.13-66.96,127.38,38.28-20.13,66.96c-1.7,5.67-7.68,8.88-13.34,7.17Z"/>
            </g>
            <path class="cls-5" d="m439.29,399.88l-106.86-32.12c-5.67-1.7-8.88-7.68-7.17-13.34l20.13-66.96,127.38,38.28-20.13,66.96c-1.7,5.67-7.68,8.88-13.34,7.17Z"/>
          </g>
          <g>
            <g class="cls-10">
              <path class="cls-8" d="m476.05,327.88l-128.59-38.65c-2.5-.75-3.92-3.39-3.17-5.9l2.78-9.24c.81-2.69,3.64-4.21,6.33-3.4l127.63,38.36c1.68.5,2.63,2.27,2.13,3.95l-3.96,13.19c-.4,1.33-1.8,2.09-3.13,1.69Z"/>
            </g>
            <path class="cls-2" d="m474.42,326.25l-128.59-38.65c-2.5-.75-3.92-3.39-3.17-5.9l2.78-9.24c.81-2.69,3.64-4.21,6.33-3.4l127.63,38.36c1.68.5,2.63,2.27,2.13,3.95l-3.96,13.19c-.4,1.33-1.8,2.09-3.13,1.69Z"/>
          </g>
          <g>
            <g>
              <g class="cls-10">
                <rect class="cls-8" x="413.07" y="353.68" width="5.41" height="26.96" rx=".65" ry=".65" transform="translate(-55.53 659.66) rotate(-73.27)"/>
              </g>
              <rect class="cls-3" x="411.45" y="352.06" width="5.41" height="26.96" rx=".65" ry=".65" transform="translate(-55.13 656.94) rotate(-73.27)"/>
            </g>
            <g>
              <g class="cls-10">
                <rect class="cls-8" x="412.17" y="301.78" width="7.21" height="58.36" rx="1.55" ry="1.55" transform="translate(-20.85 633.87) rotate(-73.27)"/>
              </g>
              <rect class="cls-3" x="410.55" y="300.15" width="7.21" height="58.36" rx="1.55" ry="1.55" transform="translate(-20.45 631.16) rotate(-73.27)"/>
            </g>
          </g>
          <g>
            <g class="cls-10">
              <polygon class="cls-8" points="363.02 293.91 350.07 290.02 363.96 273.86 376.9 277.76 363.02 293.91"/>
              <polygon class="cls-8" points="388.02 301.42 375.08 297.53 388.96 281.38 401.91 285.27 388.02 301.42"/>
              <polygon class="cls-8" points="413.02 308.94 400.08 305.05 413.97 288.9 426.91 292.79 413.02 308.94"/>
              <polygon class="cls-8" points="438.03 316.45 425.08 312.56 438.97 296.41 451.92 300.3 438.03 316.45"/>
              <polygon class="cls-8" points="463.03 323.97 450.09 320.08 463.97 303.93 476.92 307.82 463.03 323.97"/>
            </g>
            <g>
              <polygon class="cls-3" points="361.39 292.28 348.44 288.39 362.33 272.24 375.28 276.13 361.39 292.28"/>
              <polygon class="cls-3" points="386.39 299.79 373.45 295.9 387.34 279.75 400.28 283.64 386.39 299.79"/>
              <polygon class="cls-3" points="411.4 307.31 398.45 303.42 412.34 287.27 425.28 291.16 411.4 307.31"/>
              <polygon class="cls-3" points="436.4 314.82 423.46 310.93 437.34 294.78 450.29 298.67 436.4 314.82"/>
              <polygon class="cls-3" points="461.4 322.34 448.46 318.45 462.35 302.3 475.29 306.19 461.4 322.34"/>
            </g>
          </g>
          <g>
            <g class="cls-10">
              <path class="cls-8" d="m351.74,269.4l134.18-12.33c1.96-.18,3.4-1.92,3.22-3.88l-.87-9.5c-.2-2.21-2.16-3.84-4.37-3.63l-135.5,12.45c-.79.07-1.37.77-1.3,1.56l1.14,12.42c.09.94.59,1.8,1.36,2.34h0c.62.43,1.38.63,2.13.56Z"/>
              <polygon class="cls-8" points="354.7 251.93 363.85 268.29 377.31 267.05 368.16 250.7 354.7 251.93"/>
              <polygon class="cls-8" points="380.7 249.55 389.85 265.9 403.31 264.66 394.16 248.31 380.7 249.55"/>
              <polygon class="cls-8" points="406.7 247.16 415.84 263.51 429.3 262.27 420.16 245.92 406.7 247.16"/>
              <polygon class="cls-8" points="432.7 244.77 441.84 261.12 455.3 259.88 446.16 243.53 432.7 244.77"/>
              <polygon class="cls-8" points="458.7 242.38 467.84 258.73 481.3 257.5 472.16 241.14 458.7 242.38"/>
            </g>
            <g>
              <path class="cls-7" d="m350.11,267.77l134.18-12.33c1.96-.18,3.4-1.92,3.22-3.88l-.87-9.5c-.2-2.21-2.16-3.84-4.37-3.63l-135.5,12.45c-.79.07-1.37.77-1.3,1.56l1.14,12.42c.09.94.59,1.8,1.36,2.34h0c.62.43,1.38.63,2.13.56Z"/>
              <g>
                <polygon class="cls-3" points="353.07 250.31 362.22 266.66 375.68 265.42 366.53 249.07 353.07 250.31"/>
                <polygon class="cls-3" points="379.07 247.92 388.22 264.27 401.68 263.03 392.53 246.68 379.07 247.92"/>
                <polygon class="cls-3" points="405.07 245.53 414.22 261.88 427.68 260.65 418.53 244.29 405.07 245.53"/>
                <polygon class="cls-3" points="431.07 243.14 440.22 259.49 453.68 258.26 444.53 241.9 431.07 243.14"/>
                <polygon class="cls-3" points="457.07 240.75 466.21 257.1 479.67 255.87 470.53 239.51 457.07 240.75"/>
              </g>
            </g>
          </g>
        </g>
        <rect class="cls-2" x="165.34" y="380.17" width="22.57" height="71.4" rx="11.02" ry="11.02" transform="translate(-260.08 299.97) rotate(-52.12)"/>
        <path class="cls-5" d="m30.57,258.8c-.54-1.71-.84-3.54-.84-5.43,0-9.9,8.03-17.93,17.93-17.93,2.94,0,5.71.71,8.16,1.97,0-.06,0-.12,0-.18,0-8.39,6.8-15.19,15.19-15.19.68,0,1.35.06,2.01.15.04,0,.08,0,.12,0,13.05,1.63,28.31,22.51,42.73,37.39l68.76-81.98c13.97-19.78,37.01-32.71,63.06-32.71,42.61,0,77.15,34.54,77.15,77.15,0,19.9-7.54,38.05-19.92,51.73l-106.78,127.32c-9.69,11.55-26.9,13.06-38.45,3.37l-97.06-81.4-37.93-32.61c-5.44-5.1-10.19-10.47-10.42-14.36-.51-8.52,6.86-13.44,16.31-17.28Z"/>
        <g class="cls-9">
          <path d="m365.77,353.48c3.89-1.86,5.98-6.09,5.11-10.06-.76-3.44-3.62-6.16-7.24-6.84,3.26-1.87,5.12-5.35,4.67-8.69-.58-4.32-4.95-7.83-10.09-7.38,3.92-2.21,5.62-7.03,3.96-11.18-1.68-4.21-6.33-6.53-10.74-5.32-12.66.77-29.48.56-48.77-3.16-16.96-3.27-46.33-18.07-64.18-29.95-.31-.22-.61-.45-.93-.67-.39-.26-.78-.51-1.17-.76-.5-.34-.99-.68-1.46-1.02-.03.02-.06.04-.09.07-20.45-12.01-46.45-7.02-59.34,11.93-13.47,19.8-7.51,47.32,13.29,61.48.55.37,1.1.72,1.66,1.07,0,0,0,0,0,0,20.99,13.62,64.52,30.12,126.19,22.66,18.99-2.3,35.49-6.98,49.16-12.18Z"/>
        </g>
        <path class="cls-2" d="m362.74,352.33c3.83-1.83,5.89-6,5.03-9.91-.74-3.39-3.56-6.07-7.13-6.74,3.21-1.84,5.04-5.28,4.6-8.56-.57-4.26-4.88-7.72-9.94-7.27,3.87-2.18,5.53-6.93,3.9-11.02-1.66-4.15-6.24-6.43-10.59-5.25-12.48.76-29.05.55-48.06-3.12-16.71-3.22-45.66-17.81-63.25-29.51-.31-.22-.6-.44-.92-.66-.38-.26-.77-.5-1.15-.74-.49-.34-.97-.67-1.44-1-.03.02-.06.04-.09.07-20.15-11.84-45.77-6.92-58.47,11.75-13.27,19.51-7.4,46.63,13.1,60.58.54.37,1.08.71,1.63,1.05,0,0,0,0,0,0,20.68,13.42,63.58,29.68,124.34,22.33,18.71-2.26,34.97-6.88,48.44-12Z"/>
        <rect class="cls-2" x="305.54" y="200.96" width="54.94" height="25.91" rx="12.96" ry="12.96" transform="translate(-33.12 63.56) rotate(-10.39)"/>
        <rect class="cls-7" x="260.66" y="191.24" width="14.76" height="30.51" rx="6.26" ry="6.26" transform="translate(459.18 485.71) rotate(-162)"/>
        <rect class="cls-7" x="182.57" y="163.89" width="130.88" height="13.79" rx="5.79" ry="5.79" transform="translate(-11.94 18.8) rotate(-4.24)"/>
        <g class="cls-11">
          <g class="cls-10">
            <path class="cls-6" d="m288.16,173.98c11.43-1.81,22.18-2.41,32.03-2.25,3,.05,5.99.35,8.95.84,11.41,1.91,22.82,3.82,34.23,5.73,2.3.38,2.31,3.69,0,4.08-16.83,2.88-33.67,5.77-50.5,8.65-8.68,1.49-17.66-.55-24.63-5.92-1.24-.95-2.46-2.02-3.66-3.21-.02-.02-.04-.04-.06-.06-2.55-2.57-1.13-7.08,2.39-7.66.41-.07.83-.14,1.25-.2Z"/>
          </g>
          <path class="cls-1" d="m286.16,171.98c11.43-1.81,22.18-2.41,32.03-2.25,3,.05,5.99.35,8.95.84,11.41,1.91,22.82,3.82,34.23,5.73,2.3.38,2.31,3.69,0,4.08-16.83,2.88-33.67,5.77-50.5,8.65-8.68,1.49-17.66-.55-24.63-5.92-1.24-.95-2.46-2.02-3.66-3.21-.02-.02-.04-.04-.06-.06-2.55-2.57-1.13-7.08,2.39-7.66.41-.07.83-.14,1.25-.2Z"/>
        </g>
        <path class="cls-2" d="m283,141.01c.13-3.07-.64-9.33-6.54-14.56-7.56-6.7-19.07-5.29-26.15-1.49-1.44.78-7.01,3.96-12.78,15.75-9.68,19.79-7.71,32.53-16.26,45.69-.87,1.33-1.7,3.06-3.32,4.31-3.6,2.77-4.83,5.37-3.96,6.93.95,1.71,4.45,2.37,9.36.35,14.75-6.07,24.25-18.22,27-23.25,3.29-6.01,6.57-11.63,8.44-15.8.69-1.55,3.72-6.56,7.65-5.55,7.02,1.81,16.05-.84,16.54-12.38Z"/>
      </g>
      <g>
        <path class="cls-4" d="m458.4,26.04l-121.69-2.54c-14.89-.31-27.22,11.51-27.53,26.4l-1.08,51.5c-.31,14.89,11.51,27.22,26.4,27.53l49.62,1.04c.57,6.79,1.12,13.67,1.73,20.23.22,2.41,3.05,3.6,4.94,2.08,8.58-6.93,17.25-14.37,25.89-21.62l39.52.83c14.89.31,27.22-11.51,27.53-26.4l1.08-51.5c.31-14.89-11.51-27.22-26.4-27.53Z"/>
        <rect class="cls-3" x="311.37" y="15.31" width="175.66" height="105.45" rx="26.97" ry="26.97" transform="translate(1.51 -8.33) rotate(1.2)"/>
        <path class="cls-3" d="m385.38,104.16c1.19,11.54,2.1,24.62,3.22,36.58.23,2.41,3.05,3.6,4.94,2.08,13.97-11.28,28.19-23.94,42.1-34.9l-50.26-3.76Z"/>
        <g>
          <path d="m338.55,76.41c-1.76,0-3.37-.29-4.83-.88s-2.71-1.42-3.76-2.5c-1.05-1.08-1.87-2.35-2.46-3.81-.59-1.46-.89-3.07-.89-4.83s.29-3.34.89-4.8c.59-1.46,1.41-2.73,2.46-3.81,1.05-1.08,2.3-1.92,3.76-2.51,1.46-.59,3.07-.88,4.83-.88s3.34.3,4.8.88c1.46.59,2.72,1.43,3.78,2.51,1.06,1.08,1.88,2.35,2.47,3.81s.88,3.06.88,4.8-.29,3.37-.88,4.83-1.42,2.73-2.47,3.81c-1.06,1.08-2.32,1.92-3.78,2.5s-3.06.88-4.8.88Zm0-2.58c1.34,0,2.57-.24,3.69-.72s2.1-1.14,2.93-2c.83-.85,1.47-1.85,1.92-3,.45-1.15.67-2.39.67-3.73s-.22-2.58-.67-3.72c-.45-1.14-1.09-2.14-1.92-3-.83-.86-1.81-1.52-2.93-1.99-1.12-.47-2.35-.71-3.69-.71s-2.57.24-3.69.71c-1.12.47-2.09,1.14-2.91,1.99-.82.86-1.46,1.86-1.92,3-.46,1.14-.69,2.38-.69,3.72s.23,2.58.69,3.73c.46,1.15,1.1,2.15,1.92,3,.82.85,1.79,1.52,2.91,2s2.35.72,3.69.72Z"/>
          <path d="m362.04,76.26c-1.42,0-2.68-.32-3.78-.98-1.1-.65-1.96-1.55-2.59-2.71-.63-1.16-.95-2.48-.95-3.96v-7.56c0-.4.13-.73.39-.99s.59-.39.99-.39.76.13,1.02.39.39.59.39.99v7.56c0,1,.21,1.89.64,2.65.43.77,1.02,1.38,1.77,1.82s1.6.66,2.56.66,1.77-.17,2.49-.52c.72-.35,1.29-.83,1.7-1.44.41-.61.62-1.31.62-2.08l1.5.03c-.02,1.26-.32,2.39-.9,3.39-.58,1-1.38,1.78-2.38,2.33-1.01.55-2.17.82-3.46.82Zm9.63,5.1c-.4,0-.73-.13-.98-.39-.25-.26-.38-.6-.38-1.02v-3.84h-.57c-.86,0-1.49-.2-1.88-.6-.39-.4-.58-1.02-.58-1.86v-12.6c0-.4.13-.73.39-.99s.59-.39.99-.39.73.13.99.39.39.59.39.99v12.69h1.44c.42,0,.76.13,1.02.39.26.26.39.6.39,1.02v4.8c0,.42-.11.76-.33,1.02-.22.26-.52.39-.9.39Z"/>
          <path d="m383.76,76.23c-1.66,0-3.12-.35-4.4-1.06s-2.26-1.69-2.98-2.94c-.72-1.25-1.08-2.69-1.08-4.31s.34-3.08,1.02-4.33,1.62-2.23,2.82-2.94,2.59-1.06,4.17-1.06,2.87.34,3.99,1.03c1.12.69,1.98,1.64,2.58,2.85.6,1.21.9,2.61.9,4.19,0,.34-.12.63-.34.85-.23.23-.54.35-.92.35h-12.33v-2.22h12.36l-1.23.87c-.02-1.06-.23-2.01-.63-2.85-.4-.84-.97-1.5-1.71-1.98-.74-.48-1.63-.72-2.67-.72-1.14,0-2.12.26-2.96.78s-1.47,1.23-1.91,2.13-.66,1.92-.66,3.06.26,2.16.77,3.04c.51.89,1.21,1.6,2.1,2.12s1.92.78,3.11.78c.64,0,1.3-.12,2-.36.69-.24,1.23-.52,1.63-.84.28-.22.58-.33.9-.34.32,0,.6.09.84.29.3.28.46.58.48.91.02.33-.12.62-.42.85-.68.54-1.53.99-2.54,1.34s-1.98.52-2.89.52Z"/>
          <path d="m404.79,76.14c-.4,0-.72-.13-.96-.39-.24-.26-.36-.59-.36-.99v-13.68c0-.42.12-.75.36-1.01.24-.25.56-.38.96-.38.42,0,.76.13,1.02.38.26.25.39.58.39,1.01v5.37h3.75v2.55h-3.75v5.76c0,.4-.13.73-.39.99-.26.26-.6.39-1.02.39Zm11.79-.36c-.24.22-.57.33-.99.31-.42,0-.75-.13-.99-.38l-7.23-7.83,6.87-7.77c.24-.24.56-.38.96-.42.4-.04.72.05.96.27.28.26.44.55.48.87.04.32-.06.61-.3.87l-5.52,6.03,5.82,6.33c.24.24.36.52.36.84s-.14.61-.42.87Z"/>
          <path d="m422.49,56.85c-.5,0-.93-.18-1.29-.54-.36-.36-.54-.8-.54-1.32s.18-.95.55-1.3.8-.53,1.3-.53.91.18,1.28.53.55.79.55,1.3-.18.96-.54,1.32c-.36.36-.8.54-1.32.54Zm.03,19.26c-.42,0-.76-.13-1.02-.39-.26-.26-.39-.59-.39-.99v-13.65c0-.42.13-.75.39-1.01.26-.25.6-.38,1.02-.38s.73.13.99.38c.26.25.39.58.39,1.01v13.65c0,.4-.13.73-.39.99-.26.26-.59.39-.99.39Z"/>
          <path d="m431.01,76.11c-.4,0-.72-.13-.97-.39-.25-.26-.38-.59-.38-.99v-13.65c0-.42.12-.75.38-1.01.25-.25.57-.38.97-.38s.73.13.99.38c.26.25.39.58.39,1.01v13.65c0,.4-.13.73-.39.99-.26.26-.59.39-.99.39Zm.99-7.2v-2.46h11.01v2.46h-11.01Zm11.55,7.2c-.4,0-.73-.13-.99-.39-.26-.26-.39-.59-.39-.99v-13.65c0-.42.13-.75.39-1.01.26-.25.59-.38.99-.38s.75.13.98.38c.23.25.34.58.34,1.01v13.65c0,.4-.12.73-.34.99-.23.26-.56.39-.98.39Z"/>
          <path d="m457.71,76.23c-1.6,0-3.03-.35-4.27-1.06-1.25-.71-2.23-1.69-2.94-2.94s-1.06-2.69-1.06-4.31.35-3.08,1.06-4.33,1.69-2.23,2.94-2.94c1.25-.71,2.67-1.06,4.27-1.06s3.03.35,4.28,1.06,2.23,1.69,2.94,2.94c.71,1.25,1.07,2.7,1.09,4.33,0,1.62-.36,3.06-1.08,4.31s-1.7,2.23-2.94,2.94-2.67,1.06-4.29,1.06Zm0-2.46c1.1,0,2.07-.25,2.91-.75s1.5-1.19,1.98-2.07c.48-.88.72-1.89.72-3.03s-.24-2.15-.72-3.04c-.48-.89-1.14-1.58-1.98-2.08s-1.81-.75-2.91-.75-2.04.25-2.89.75c-.85.5-1.51,1.2-2,2.08-.48.89-.72,1.9-.72,3.04s.24,2.15.72,3.03c.48.88,1.14,1.57,2,2.07.85.5,1.81.75,2.89.75Z"/>
          <path d="m471.3,76.11c-.54,0-1-.19-1.38-.57-.38-.38-.57-.84-.57-1.38s.19-1.03.57-1.41c.38-.38.84-.57,1.38-.57s1,.2,1.38.58c.38.39.57.85.57,1.4s-.2,1-.58,1.38c-.39.38-.85.57-1.37.57Zm0-5.46c-.4,0-.74-.14-1-.42-.27-.28-.41-.6-.41-.96v-15.18c0-.4.13-.73.41-1,.27-.27.6-.41,1-.41s.73.14.99.41c.26.27.39.6.39,1v15.18c0,.38-.14.71-.42.97-.28.27-.6.41-.96.41Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
  </div>
</template>


<style lang="scss">
.kino-loader {
      .cls-1 {
        fill: #ededed;
      }

      .cls-2 {
        fill: #ffa305;
      }

      .cls-3 {
        fill: #fff;
      }

      .cls-4 {
        fill: #8e6354;
      }

      .cls-5 {
        fill: #a69fd6;
      }

      .cls-6 {
        fill: #1a1b1e;
      }

      .cls-7 {
        fill: #2b303a;
      }

      .cls-8 {
        fill: #3a4254;
      }

      .cls-9, .cls-10 {
        opacity: .2;
      }

      .cls-11 {
        opacity: .7;
      }

      .cls-12 {
        isolation: isolate;
      }

      .cls-10 {
        mix-blend-mode: multiply;
      }
    }
    </style>