import { defineStore } from "pinia";
import { useLocalStorage, useSessionStorage } from '@vueuse/core'
import OwnerApi from '@/api/owner.api'
import isEmpty from "lodash.isempty";


export const useOwnerStore = defineStore('ownerStore', {
  state: () => ({
    owners: {},
    ownersLoaded: false,

    localStorage_currentOwnerId: useLocalStorage("currentOwnerId", ''),
    sessionStorage_currentOwnerId: useSessionStorage("currentOwnerId", ''),

    // currentOwnerId: useLocalStorage('currentOwnerId', ''),
    selectedSharedOwner: useLocalStorage('selectedSharedOwner', ''),
  }),

  getters: {
    currentOwnerId() {
      // If key is initialized in sessionStorage, return it`s value
      if (!isEmpty(this.sessionStorage_currentOwnerId)) {
        return this.sessionStorage_currentOwnerId
      }

      // If key is initialized in localStorage
      if (!isEmpty(this.localStorage_currentOwnerId)) {
        // Initialize this key in session storage
        this.sessionStorage_currentOwnerId = this.localStorage_currentOwnerId
        // Return sessionStorage key value
        return this.sessionStorage_currentOwnerId
      }

      // return undefined if key not in sessionStorage nor localStorage
      return undefined
    },

    ownerOptions() {
      if (this.owners) {
        return Object.values(this.owners).map((x) => {
          return {
            title: x.title,
            value: x.id
          }
        })
      } else {
        return []
      }
    },

    selectedOwner() {
      if (this.currentOwnerId) {
        return this.owners[this.currentOwnerId]
      }
    }
  },

  actions: {
    selectOwner(ownerId) {
      if (!isEmpty(this.owners)) {
        this.sessionStorage_currentOwnerId = ownerId
        this.localStorage_currentOwnerId = ownerId
      }
    },

    async loadAvailableOwners() {
      if (!this.ownersLoaded) {
        let owners = await OwnerApi.listAvailableOwners()
        owners.forEach(owner => {
          this.owners[owner.id] = owner
        });

        // select first owner as default
        if (!this.currentOwnerId && owners.length > 0) {
          this.selectOwner(owners[0].id)
        }
      }

      this.ownersLoaded = true
    },

    async createOwner(ownerObj) {
      let owner = await OwnerApi.createOwner(ownerObj)
      this.owners[owner.id] = owner
      return owner
    },

    async updateOwner(ownerObj) {
      let owner = await OwnerApi.updateOwner(ownerObj)
      this.owners[owner.id] = owner
      return owner
    },

    async getOwner(ownerId, useCache=true) {
			// Check if it`s already available in store and we can pick it from here
			let cacheExists = ownerId in this.owners

			// Return from store if item is available, otherwise do api call
      if (cacheExists && useCache) {
        return this.owners[ownerId]
      } else {
        let owner = await OwnerApi.getOwner(ownerId)
        this.owners[owner.id] = owner
        return owner
      }
		},

  }
})