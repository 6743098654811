<template>
  <!-- Navbar Container -->
  <div class="fixed lg:absolute lg:left-0 top-0 h-full w-screen lg:w-auto flex shrink-0 z-[51] duration-150"
    :class="navBarStore.mobileMenuOpen ? 'left-0' : '-left-full'">

    <div class="relative h-full w-80 flex flex-col shrink-0 lg:duration-150 gap-y-4 lg:px-2 lg:pr-0"
      :class="menuWidthClass">

      <NavBarHeader @changeTheme="emitChangeTheme" />
      <NavBarMain/>
      <NavBarFooter class="hidden lg:flex"/>

      <div class="absolute flex lg:hidden w-full h-full inset-0 s-card-fill-1 z-[-1]" />
    </div>

    <div class="flex w-full h-full inset-0 cursor-pointer lg:hidden" @click="hideMobileNavBar" />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";

import NavBarHeader from "@/components/reworked/widgets/navbar/NavBarHeader.vue";
import NavBarMain from "@/components/reworked/widgets/navbar/NavBarMain.vue";



import { useNavBarStore } from "@/store/navBarStore";

import "@/styles/scroll.scss";
import NavBarFooter from './NavBarFooter.vue'

const navBarStore = useNavBarStore();


const menuWidthClass = computed(() => {

  if (navBarStore.menuCollapsed) {
    return 'lg:w-20'
  }
  return 'lg:w-80'
})


const hideMobileNavBar = () => {
  navBarStore.hideMobileMenu();
}


const emit = defineEmits(['change-theme']);

const emitChangeTheme = () => {
  emit('change-theme');
}


onMounted(async () => {
  navBarStore.setStartMenuValue();
  navBarStore.setStartContentWidthValue();
})


</script>