import { defineStore } from "pinia";
import ContactApi from '@/api/contact.api'
import isEmpty from "lodash.isempty";

export const useContactStore = defineStore('contactStore', {
  state: () => ({
    contacts: {},
    contactsLoaded: false,

    authenticatedUserContact: {},
    authenticatedUserAccess: ''
  }),

  getters: {},

  actions: {
    async listContactsByOwner(force=false) {
      if (!this.contactsLoaded || force) {
        let contacts = await ContactApi.listContactsByOwner()
        contacts.forEach(contact => {
          this.contacts[contact.email] = contact
        });

        this.contactsLoaded = true
      }

      return this.contacts
    },

    async getContactByEmail(email, useCache=true) {
      let cacheExists = email in this.contacts

      if (cacheExists && useCache) {
        return this.contacts[email]
      }
      else {
        let contact = await ContactApi.getContactByEmail(email)
        this.contacts[contact.email] = contact
        return contact
      }
    },

    async getAuthenticatedUserContact(email, useCache=true) {
      let cacheExists = !isEmpty(this.authenticatedUserContact)

      if (cacheExists && useCache) {
        return this.authenticatedUserContact
      } else {
        let contact = await ContactApi.getContactByEmail(email)
        this.authenticatedUserContact = contact
        this.authenticatedUserAccess = contact.access
        return contact
      }
    },

    async createContact(contactObj) {
      let contact = await ContactApi.createContact(contactObj)
      this.contacts[contact.email] = contact
      return contact
    },

    async updateContact(contactObj) {
      let contact = await ContactApi.updateContact(contactObj)
      this.contacts[contact.email] = contact
      return contact
    },

    async deleteContact(contactObj) {
      await ContactApi.deleteContact(contactObj.SK)
      delete this.contacts[contactObj.email]
    },

    async addLocationsByMask(contactEmail, locationMask) {
      let contact = await ContactApi.contactAddLocationsByMask(contactEmail, locationMask)
      this.contacts[contact.email] = contact
      return contact
    }
  }
})