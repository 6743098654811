import axios from 'axios'
import { useAuthStore } from '@/store/authStore'
import { Auth } from 'aws-amplify';
import loadingHelper from '../helpers/loadingHelper'

// Toaster
import { dangerToast } from '@/helpers/toastHelpers';

const soloveykoApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

soloveykoApi.interceptors.request.use(
	(config) => {
		if(!config.hideLoader) {
			loadingHelper.loadingOn('soloveykoApi')
		}

		const authStore = useAuthStore()
		config.headers['Authorization'] = authStore.idToken

		config.maxContentLength = Infinity
		config.maxBodyLength = Infinity
		
		return config
	},
  (error) => {
    return Promise.reject(error);
  }
);

// Actions which depend on response status code and Authorization errors
soloveykoApi.interceptors.response.use((response)=>{
	loadingHelper.loadingOff('soloveykoApi');
	return response;

}, async (error) => {
	const originalRequest = error.config;

	loadingHelper.loadingOff('soloveykoApi');

	if (error.response?.status === 401) {
		// Custom soloveyko auth control. User should always work in certain owner account
		if (error.response.data.message === "Authorization error.") {
			dangerToast('Організацію не обрано!', 'Перейдіть до налаштувань та оберіть бажану організацію.')
			return Promise.reject(error);
		}

		// Custom soloveyko auth control. User has no access to certain records
		if (error.response.data.message === "Access denied") {
			dangerToast('Відмовлено у доступі!', 'Відсутній доступ до обраної організації.')
			return Promise.reject(error);
		}

		if ((error.response.data.message === "Unauthorized" || error.response.data.message === "The incoming token has expired") && !originalRequest._retry) {
			originalRequest._retry = true;

			// refresh session
			let session = await Auth.currentSession()

			const authStore = useAuthStore()
			authStore.userSession = session

			// resend request with new token
			originalRequest.headers['Authorization'] = authStore.idToken
			return soloveykoApi(originalRequest)
		}
	}
	return Promise.reject(error);
})

export default soloveykoApi
