import { useContactStore } from '@/store/contactStore'

export const accessAllowed = (allowedAccessLevels) => {
  if (!Array.isArray(allowedAccessLevels) || allowedAccessLevels.length === 0) {
    console.log('Invalid or empty allowedAccessLevels parameter');
    return false
  }

  const contactStore = useContactStore();
  if (allowedAccessLevels.includes(contactStore.authenticatedUserAccess)) {
    return true
  } else {
    return false
  }
}