<template>
    <div class="flex h-full w-full s-coffee-loader">
      <svg class="flex m-auto max-h-full max-w-full"
            version="1.1"
            id="_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            xml:space="preserve"
            height="500"
            width="500"
            >
            <g class="cls-11">
    <g id="s-coffee-loader">
      <path class="cls-6" d="m195.53,476.79c-4.72,0-9.31-1.68-12.93-4.73l-33.5-28.23c-7.05-5.94-9-15.75-5.35-23.76l-79.82-66.95-.09-.08c-.33-.29-32.84-29.52-43.19-38.07l-.2-.16-.19-.18c-7.62-7.3-8.73-14.5-8.32-19.25.61-7.04,3.29-12.4,7.96-15.94,2.19-1.66,4.73-2.86,7.74-3.66.15-13.92,11.52-25.2,25.47-25.2,1.04,0,2.08.06,3.11.19,3.4-8.21,11.5-14,20.93-14,.83,0,1.67.05,2.61.17.16.01.33.03.51.05,12.87,1.6,25.02,15.13,37.87,29.44,1.63,1.81,3.28,3.66,4.93,5.46l65.58-78.18c13.61-19.13,34.35-31.89,57.39-35.41.91-1.54,1.86-3.06,2.87-4.58,7.68-11.57,15.99-16.01,20.25-17.62,3.54-1.34,7.41-2.05,11.17-2.05,8.41,0,16,3.42,21.39,9.63,4.95,5.7,7.1,12.75,6.4,20.94-.23,2.65-.81,4.91-1.6,6.81,16.39,10.74,28.68,26.74,34.79,45.32l15.46-2.83c1.19-.22,2.4-.33,3.6-.33,9.59,0,17.81,6.85,19.54,16.29,1.98,10.78-5.19,21.16-15.97,23.14l-19.25,3.53c-2.21,15-8.23,28.93-17.65,40.81,5.37-.12,10.75-.5,16.06-1.13,9.93-1.19,19.79-3.29,29.37-6.26l.04-3.11c.03-2.52,1.11-4.79,2.81-6.41l.07-4.28c-2.63-2.32-4.12-5.86-3.67-9.6l.4-3.37c.66-5.56,5.38-9.74,10.97-9.74.44,0,.88.03,1.32.08l36.6,4.37c2.43-2.18,5.63-3.46,9.03-3.46.53,0,1.07.03,1.61.1l1.87.22c2.31.28,4.38,1.44,5.82,3.27.59.75,1.05,1.58,1.36,2.45,2.03.67,3.82,1.93,5.18,3.65,1.83,2.32,2.64,5.21,2.29,8.15l-.4,3.37c-.44,3.72-2.71,6.83-5.83,8.47l-.9,3.98c1.23,1.79,1.76,3.96,1.52,6.11.11,1,.06,2.01-.17,3l-5.58,24.33c-.62,2.68-2.35,4.83-4.65,6l-2.7,11.97c-1.28,5.67-6.23,9.63-12.04,9.63-.49,0-.98-.03-1.47-.09l-15.67-1.87c-7.73,5.01-15.84,9.47-24.13,13.27-18.67,8.56-38.68,14.04-59.65,16.35,2.78,6.46,1.15,14.16-4.12,18.71-2.07,1.79-4.57,2.99-7.24,3.53-.63,2.68-1.94,5.17-3.87,7.14-2.81,2.87-6.82,4.51-11.02,4.53-1.24,3.2-3.5,6.01-6.59,7.91-2.52,1.54-5.41,2.35-8.37,2.35-4.75,0-9.17-2.02-12.24-5.58-6.84-5.75-14.13-11.06-21.74-15.82l-27.81,33.16c-2.17,2.58-4.64,4.81-7.38,6.64l2.31,1.94c8.47,7.13,9.58,19.83,2.48,28.31-3.82,4.56-9.42,7.17-15.36,7.17Z"/>
      <path class="cls-3" d="m197.53,468.79c-4.72,0-9.31-1.68-12.93-4.73l-33.5-28.23c-7.05-5.94-9-15.75-5.35-23.76l-79.82-66.95-.09-.08c-.33-.29-32.84-29.52-43.19-38.07l-.2-.16-.19-.18c-7.62-7.3-8.73-14.5-8.32-19.25.61-7.04,3.29-12.4,7.96-15.94,2.19-1.66,4.73-2.86,7.74-3.66.15-13.92,11.52-25.2,25.47-25.2,1.04,0,2.08.06,3.11.19,3.4-8.21,11.5-14,20.93-14,.83,0,1.67.05,2.61.17.16.01.33.03.51.05,12.87,1.6,25.02,15.13,37.87,29.44,1.63,1.81,3.28,3.66,4.93,5.46l65.58-78.18c13.61-19.13,34.35-31.89,57.39-35.41.91-1.54,1.86-3.06,2.87-4.58,7.68-11.57,15.99-16.01,20.25-17.62,3.54-1.34,7.41-2.05,11.17-2.05,8.41,0,16,3.42,21.39,9.63,4.95,5.7,7.1,12.75,6.4,20.94-.23,2.65-.81,4.91-1.6,6.81,16.39,10.74,28.68,26.74,34.79,45.32l15.46-2.83c1.19-.22,2.4-.33,3.6-.33,9.59,0,17.81,6.85,19.54,16.29,1.98,10.78-5.19,21.16-15.97,23.14l-19.25,3.53c-2.21,15-8.23,28.93-17.65,40.81,5.37-.12,10.75-.5,16.06-1.13,9.93-1.19,19.79-3.29,29.37-6.26l.04-3.11c.03-2.52,1.11-4.79,2.81-6.41l.07-4.28c-2.63-2.32-4.12-5.86-3.67-9.6l.4-3.37c.66-5.56,5.38-9.74,10.97-9.74.44,0,.88.03,1.32.08l36.6,4.37c2.43-2.18,5.63-3.46,9.03-3.46.53,0,1.07.03,1.61.1l1.87.22c2.31.28,4.38,1.44,5.82,3.27.59.75,1.05,1.58,1.36,2.45,2.03.67,3.82,1.93,5.18,3.65,1.83,2.32,2.64,5.21,2.29,8.15l-.4,3.37c-.44,3.72-2.71,6.83-5.83,8.47l-.9,3.98c1.23,1.79,1.76,3.96,1.52,6.11.11,1,.06,2.01-.17,3l-5.58,24.33c-.62,2.68-2.35,4.83-4.65,6l-2.7,11.97c-1.28,5.67-6.23,9.63-12.04,9.63-.49,0-.98-.03-1.47-.09l-15.67-1.87c-7.73,5.01-15.84,9.47-24.13,13.27-18.67,8.56-38.68,14.04-59.65,16.35,2.78,6.46,1.15,14.16-4.12,18.71-2.07,1.79-4.57,2.99-7.24,3.53-.63,2.68-1.94,5.17-3.87,7.14-2.81,2.87-6.82,4.51-11.02,4.53-1.24,3.2-3.5,6.01-6.59,7.91-2.52,1.54-5.41,2.35-8.37,2.35-4.75,0-9.17-2.02-12.24-5.58-6.84-5.75-14.13-11.06-21.74-15.82l-27.81,33.16c-2.17,2.58-4.64,4.81-7.38,6.64l2.31,1.94c8.47,7.13,9.58,19.83,2.48,28.31-3.82,4.56-9.42,7.17-15.36,7.17Z"/>
      <g id="s-coffee-loader-2" data-name="s-coffee-loader-2">
        <path class="cls-1" d="m304.9,295.18c10.74,1.29,24.8,1.95,41.05,0,17.28-2.07,31.53-6.43,42-10.5,3.39-2.21,7.94-1.51,10.5,1.59,2.52,3.05,2.39,7.57-.32,10.5,4.21-1.77,8.84.01,10.5,3.5,1.28,2.69.66,6.13-1.59,8.59,3.24-.4,6.39,1.12,7.95,3.82,1.81,3.12,1.19,7.24-1.59,9.86-10.12,8.07-22.78,16.48-38.18,23.55-50.01,22.94-97.75,17.41-120.59,13.05,16.76-21.32,33.52-42.64,50.27-63.95Z"/>
        <path class="cls-10" d="m37.5,273.66c-.56-1.77-.87-3.65-.87-5.6,0-10.2,8.27-18.48,18.48-18.48,3.03,0,5.88.73,8.41,2.03,0-.06,0-.13,0-.19,0-8.64,7.01-15.65,15.65-15.65.7,0,1.39.06,2.07.15.05,0,.08,0,.13,0,13.45,1.68,29.17,23.19,44.02,38.52l70.83-84.45c14.39-20.38,38.12-33.69,64.97-33.69,43.89,0,79.48,35.58,79.48,79.48,0,20.51-7.77,39.2-20.52,53.29l-110.01,131.16c-9.98,11.9-27.72,13.45-39.61,3.47l-99.99-83.86s-32.83-29.52-43.41-38.27c-5.05-4.83-6.54-9.59-6.19-13.59.92-10.65,7.44-13.79,16.59-14.32Z"/>
        <path class="cls-2" d="m153.99,412.02h0c-4.64,5.54-3.91,13.8,1.62,18.46l33.5,28.23c5.53,4.66,13.77,3.95,18.41-1.59h0c4.64-5.54,3.91-13.8-1.62-18.46l-33.5-28.23c-5.53-4.66-13.77-3.95-18.41,1.59Z"/>
        <path class="cls-2" d="m311.76,370.94c2.03,3.88,1.16,8.58-1.96,11.28-2.71,2.34-6.69,2.81-10.05,1.16,1.52,3.5.9,7.46-1.49,9.9-3.1,3.16-8.78,3.36-12.68-.13,1.84,4.19.29,9.13-3.58,11.49-3.93,2.4-9.12,1.46-11.97-2.2-9.82-8.32-23.63-18.37-41.58-27.07-15.77-7.65-48.65-13.47-70.39-14.66-.39,0-.77,0-1.16-.02-.47-.02-.94-.06-1.41-.1-.61-.02-1.22-.05-1.81-.06-.01-.04-.02-.07-.03-.11-23.93-2.67-42.03-22.53-40.99-45.77,1.09-24.28,22.66-43.04,48.18-41.9.67.03,1.33.08,2,.14,0,0,0,0,0,0,25.34,1.69,70.76,14.73,116.37,58.28,14.04,13.41,24.61,27.25,32.56,39.78Z"/>
        <rect class="cls-2" x="320.88" y="215.2" width="54.61" height="25.76" rx="12.88" ry="12.88" transform="translate(-35.42 66.53) rotate(-10.39)"/>
        <rect class="cls-7" x="274.55" y="204.07" width="15.21" height="31.43" rx="6.24" ry="6.24" transform="translate(482.59 516) rotate(-162)"/>
        <g>
          <g class="cls-13">
            <path class="cls-9" d="m305.16,158.03c.24-2.84.61-9.62-4.72-15.75-6.82-7.86-17.04-8.55-24.79-5.62-1.58.6-9.42,3.69-16.9,14.95-12.56,18.91-12.26,32.18-22.75,44.48-1.06,1.25-3.72,3.9-5.54,4.96-4.05,2.35-4.09,3.83-3.41,5.55.74,1.87,8.29,1.89,13.56.4,12.21-3.45,23.17-14.13,26.67-18.9,4.17-5.69,8.28-10.99,10.75-15,.92-1.49,4.69-6.2,8.57-4.64,6.92,2.79,17.55,1.44,18.57-10.42Z"/>
          </g>
          <path class="cls-2" d="m303.16,156.03c.24-2.84.61-9.62-4.72-15.75-6.82-7.86-17.04-8.55-24.79-5.62-1.58.6-9.42,3.69-16.9,14.95-12.56,18.91-12.26,32.18-22.75,44.48-1.06,1.25-3.72,3.9-5.54,4.96-4.05,2.35-4.09,3.83-3.41,5.55.74,1.87,8.29,1.89,13.56.4,12.21-3.45,23.17-14.13,26.67-18.9,4.17-5.69,8.28-10.99,10.75-15,.92-1.49,4.69-6.2,8.57-4.64,6.92,2.79,17.55,1.44,18.57-10.42Z"/>
        </g>
        <g id="cup">
          <g class="cls-12">
            <path class="cls-9" d="m390.22,332.46l30.54,3.64c2.72.32,5.25-1.46,5.85-4.13l13.47-59.72c.7-3.09-1.43-6.11-4.58-6.49l-43.05-5.14c-3.15-.38-5.93,2.06-5.98,5.23l-.96,61.21c-.04,2.74,1.99,5.07,4.71,5.39Z"/>
            <path class="cls-9" d="m390.22,332.46l30.54,3.64c2.72.32,5.25-1.46,5.85-4.13l13.47-59.72c.7-3.09-1.43-6.11-4.58-6.49l-43.05-5.14c-3.15-.38-5.93,2.06-5.98,5.23l-.96,61.21c-.04,2.74,1.99,5.07,4.71,5.39Z"/>
            <rect class="cls-9" x="408.19" y="232.13" width="11.5" height="62.58" rx="4.05" ry="4.05" transform="translate(103.34 643.24) rotate(-83.2)"/>
            <path class="cls-9" d="m427.15,264.76l10.05,1.2,1-8.34c.11-.94-.56-1.8-1.5-1.91l-1.87-.22c-3.58-.43-6.82,2.13-7.25,5.7l-.43,3.58Z"/>
            <path class="cls-9" d="m384.99,309.92l46.8,5.58c1.02.12,1.96-.54,2.19-1.54l5.59-24.34c.27-1.16-.53-2.3-1.71-2.44l-52.09-6.22c-1.18-.14-2.23.77-2.24,1.96l-.3,24.97c-.01,1.02.75,1.89,1.77,2.01Z"/>
            <path class="cls-9" d="m384.99,307.92l46.8,5.58c1.02.12,1.96-.54,2.19-1.54l5.59-24.34c.27-1.16-.53-2.3-1.71-2.44l-52.09-6.22c-1.18-.14-2.23.77-2.24,1.96l-.3,24.97c-.01,1.02.75,1.89,1.77,2.01Z"/>
          </g>
          <g>
            <path class="cls-8" d="m388.22,330.46l30.54,3.64c2.72.32,5.25-1.46,5.85-4.13l13.47-59.72c.7-3.09-1.43-6.11-4.58-6.49l-43.05-5.14c-3.15-.38-5.93,2.06-5.98,5.23l-.96,61.21c-.04,2.74,1.99,5.07,4.71,5.39Z"/>
            <path class="cls-8" d="m388.22,330.46l30.54,3.64c2.72.32,5.25-1.46,5.85-4.13l13.47-59.72c.7-3.09-1.43-6.11-4.58-6.49l-43.05-5.14c-3.15-.38-5.93,2.06-5.98,5.23l-.96,61.21c-.04,2.74,1.99,5.07,4.71,5.39Z"/>
            <rect class="cls-10" x="406.19" y="230.13" width="11.5" height="62.58" rx="4.05" ry="4.05" transform="translate(103.56 639.49) rotate(-83.2)"/>
            <path class="cls-10" d="m425.15,262.76l10.05,1.2,1-8.34c.11-.94-.56-1.8-1.5-1.91l-1.87-.22c-3.58-.43-6.82,2.13-7.25,5.7l-.43,3.58Z"/>
            <g>
              <g class="cls-12">
                <path class="cls-9" d="m382.99,307.92l46.8,5.58c1.02.12,1.96-.54,2.19-1.54l5.59-24.34c.27-1.16-.53-2.3-1.71-2.44l-52.09-6.22c-1.18-.14-2.23.77-2.24,1.96l-.3,24.97c-.01,1.02.75,1.89,1.77,2.01Z"/>
              </g>
              <path class="cls-7" d="m382.99,305.92l46.8,5.58c1.02.12,1.96-.54,2.19-1.54l5.59-24.34c.27-1.16-.53-2.3-1.71-2.44l-52.09-6.22c-1.18-.14-2.23.77-2.24,1.96l-.3,24.97c-.01,1.02.75,1.89,1.77,2.01Z"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path class="cls-6" d="m457.28,150.02c-.66,0-1.33-.03-2-.09l-140.73-12.23c-12.57-1.09-21.91-12.21-20.82-24.78l7-80.54c1.02-11.72,11.02-20.9,22.78-20.9.66,0,1.33.03,2,.09l140.73,12.23c12.57,1.09,21.91,12.21,20.82,24.78l-7,80.54c-1.02,11.72-11.02,20.9-22.78,20.9Z"/>
        <path class="cls-3" d="m460.28,146.02c-.66,0-1.33-.03-2-.09l-140.73-12.23c-12.57-1.09-21.91-12.21-20.82-24.78l7-80.54c1.02-11.72,11.02-20.9,22.78-20.9.66,0,1.33.03,2,.09l140.73,12.23c12.57,1.09,21.91,12.21,20.82,24.78l-7,80.54c-1.02,11.72-11.02,20.9-22.78,20.9Z"/>
        <rect class="cls-8" x="306.89" y="20.45" width="173.03" height="112.6" rx="15.88" ry="15.88" transform="translate(8.12 -33.77) rotate(4.97)"/>
        <rect class="cls-5" x="343.22" y="-3.63" width="100.35" height="160.77" rx="9.75" ry="9.75" transform="translate(503.92 -308.53) rotate(94.97)"/>
        <rect class="cls-10" x="344.87" y="-.86" width="97.04" height="156.79" rx="9.47" ry="9.47" transform="translate(504.68 -307.67) rotate(94.97)"/>
        <rect class="cls-4" x="330.43" y="28.73" width="11.64" height="7.24" rx="1.81" ry="1.81" transform="translate(4.06 -28.99) rotate(4.97)"/>
        <rect class="cls-8" x="329.5" y="39.47" width="11.64" height="7.24" rx="1.81" ry="1.81" transform="translate(4.99 -28.86) rotate(4.97)"/>
        <rect class="cls-2" x="328.57" y="50.2" width="11.64" height="7.24" rx="1.81" ry="1.81" transform="translate(5.91 -28.74) rotate(4.97)"/>
        <g>
          <path class="cls-3" d="m325.49,89.38c-.4,0-.74-.13-1-.4-.27-.27-.41-.6-.41-1.01v-20.61c0-.42.13-.76.41-1.02.27-.26.6-.39,1-.39s.76.13,1.02.39c.26.26.39.6.39,1.02v8.91h4.86v2.49h-4.86v9.21c0,.4-.13.74-.39,1.01s-.6.4-1.02.4Zm15.45-.36c-.24.26-.58.38-1,.38-.43-.01-.77-.15-1.01-.44l-9.81-11.52,9.09-11.04c.26-.3.59-.45.99-.46.4-.01.73.12.99.4.26.3.38.6.38.92-.01.31-.15.62-.41.92l-7.65,9.12,8.49,9.87c.24.3.37.61.38.93,0,.32-.13.63-.43.93Z"/>
          <path class="cls-3" d="m350.39,89.5c-1.5,0-2.84-.36-4.02-1.08-1.18-.72-2.11-1.71-2.79-2.97-.68-1.26-1.02-2.68-1.02-4.26s.36-3.02,1.09-4.27c.73-1.25,1.72-2.24,2.97-2.97,1.25-.73,2.66-1.09,4.21-1.09s2.99.36,4.23,1.09c1.24.73,2.22,1.73,2.96,2.98.73,1.26,1.1,2.68,1.12,4.26l-1.08.27c0,1.52-.34,2.89-1.02,4.11-.68,1.22-1.59,2.18-2.74,2.88-1.15.7-2.46,1.05-3.92,1.05Zm.45-2.46c1.08,0,2.04-.26,2.88-.77.84-.51,1.5-1.21,2-2.1.49-.89.74-1.88.74-2.98s-.25-2.12-.74-3.02c-.49-.89-1.15-1.59-2-2.1-.84-.51-1.8-.76-2.88-.76s-1.99.25-2.85.76c-.86.51-1.54,1.21-2.03,2.1-.49.89-.73,1.9-.73,3.02s.24,2.1.73,2.98c.49.89,1.17,1.59,2.03,2.1.86.51,1.81.77,2.85.77Zm6.9,2.37c-.4,0-.73-.13-.99-.39-.26-.26-.39-.59-.39-.99v-4.8l.57-2.88,2.22.84v6.84c0,.4-.13.73-.41.99-.27.26-.61.39-1,.39Z"/>
          <path class="cls-3" d="m365.06,89.38c-.4,0-.73-.13-.99-.39-.26-.26-.39-.59-.39-.99v-13.68c.04-.4.18-.72.43-.98.25-.25.57-.38.95-.38.42,0,.75.13,1,.38.25.25.38.58.38,1.01v5.37h6.03v.54c1.08,0,2.03.19,2.87.57.83.38,1.48.9,1.95,1.57.47.67.71,1.45.71,2.33s-.24,1.7-.71,2.4c-.47.7-1.12,1.25-1.95,1.65-.83.4-1.79.6-2.87.6h-7.41Zm4.8-8.16v-1.5h2.91c.58,0,1.05-.2,1.41-.61.36-.41.54-.95.54-1.6,0-.56-.21-1.03-.62-1.41-.41-.38-.94-.57-1.58-.57h-7.44v-2.55h7.44c.94,0,1.77.19,2.49.56.72.37,1.29.88,1.71,1.53.42.65.63,1.4.63,2.24,0,1.2-.43,2.16-1.3,2.86-.87.71-1.96,1.06-3.29,1.06h-2.91Zm-3.42,5.58h6.03c.84,0,1.52-.2,2.05-.61.53-.41.79-.98.79-1.7,0-.76-.27-1.36-.79-1.8-.53-.44-1.21-.66-2.05-.66h-6.03v4.77Z"/>
          <path class="cls-3" d="m381.53,96.58c-.38,0-.77-.06-1.17-.17-.4-.11-.79-.28-1.17-.5-.4-.24-.66-.54-.78-.89-.12-.35-.05-.69.21-1.03.22-.3.49-.48.81-.52.32-.05.66.02,1.02.22.22.12.43.21.63.27.2.06.41.09.63.09.66,0,1.29-.32,1.89-.96.6-.64,1.17-1.58,1.71-2.82l7.29-16.62c.16-.36.41-.6.75-.72s.68-.1,1.02.06c.32.12.53.35.63.69.1.34.06.7-.12,1.08l-7.35,16.59c-.68,1.56-1.55,2.82-2.61,3.78-1.06.96-2.19,1.44-3.39,1.44Zm6.63-7.8c-.36.16-.69.18-.99.06-.3-.12-.54-.35-.72-.69l-6.81-13.38c-.18-.34-.21-.67-.08-.99.13-.32.38-.55.74-.69.34-.16.66-.18.97-.08.31.11.54.35.71.71l6.6,13.26c.18.36.24.71.18,1.05-.06.34-.26.59-.6.75Z"/>
          <path class="cls-3" d="m405.17,89.5c-1.58,0-2.99-.36-4.23-1.1-1.24-.73-2.21-1.72-2.92-2.97s-1.06-2.67-1.06-4.25.34-3.06,1.03-4.32c.69-1.26,1.64-2.25,2.84-2.96,1.2-.71,2.59-1.06,4.17-1.06,1.18,0,2.27.23,3.27.69s1.89,1.15,2.67,2.07c.26.28.36.58.29.9-.07.32-.28.6-.62.84-.24.18-.52.24-.84.18-.32-.06-.6-.23-.84-.51-1.06-1.14-2.37-1.71-3.93-1.71-1.06,0-2,.25-2.81.75-.81.5-1.44,1.19-1.89,2.05-.45.87-.67,1.9-.67,3.08s.23,2.12.71,3c.47.88,1.12,1.57,1.96,2.08.84.51,1.8.77,2.88.77.74,0,1.4-.09,1.98-.27.58-.18,1.1-.47,1.56-.87.28-.24.57-.37.88-.39.31-.02.58.07.83.27.3.26.47.55.51.87.04.32-.07.6-.33.84-1.44,1.34-3.25,2.01-5.43,2.01Z"/>
          <path class="cls-3" d="m422.99,89.65c-1.44,0-2.71-.27-3.79-.79s-1.93-1.27-2.54-2.23c-.6-.96-.9-2.08-.9-3.36v-8.94c.04-.38.19-.7.45-.96.26-.26.57-.39.93-.39.4,0,.73.13.99.39.26.26.39.59.39.99v3.72h4.92c1.3,0,2.46.23,3.46.68,1.01.45,1.81,1.1,2.4,1.95.59.85.88,1.85.88,2.98s-.3,2.19-.9,3.09c-.6.9-1.44,1.6-2.51,2.11-1.07.51-2.33.77-3.79.77Zm0-2.55c1.28,0,2.35-.29,3.21-.85.86-.57,1.29-1.43,1.29-2.57,0-1.06-.38-1.86-1.12-2.42-.75-.55-1.72-.82-2.92-.82h-4.92v2.76c0,.74.2,1.41.58,2,.39.59.92,1.06,1.58,1.4.66.34,1.43.51,2.31.51Z"/>
          <path class="cls-3" d="m435.47,89.41c-.4,0-.72-.13-.96-.39-.24-.26-.36-.59-.36-.99v-13.68c0-.42.12-.75.36-1.01.24-.25.56-.38.96-.38.42,0,.76.13,1.02.38.26.25.39.58.39,1.01v5.37h3.75v2.55h-3.75v5.76c0,.4-.13.73-.39.99-.26.26-.6.39-1.02.39Zm11.79-.36c-.24.22-.57.33-.99.31-.42,0-.75-.13-.99-.38l-7.23-7.83,6.87-7.77c.24-.24.56-.38.96-.42.4-.04.72.05.96.27.28.26.44.55.48.87.04.32-.06.61-.3.87l-5.52,6.03,5.82,6.33c.24.24.36.52.36.84s-.14.61-.42.87Z"/>
          <path class="cls-3" d="m456.92,89.5c-1.5,0-2.84-.36-4.02-1.08-1.18-.72-2.11-1.71-2.79-2.97-.68-1.26-1.02-2.68-1.02-4.26s.36-3.02,1.09-4.27c.73-1.25,1.72-2.24,2.97-2.97,1.25-.73,2.66-1.09,4.21-1.09s2.99.36,4.23,1.09c1.24.73,2.22,1.73,2.96,2.98.73,1.26,1.1,2.68,1.12,4.26l-1.08.27c0,1.52-.34,2.89-1.02,4.11-.68,1.22-1.59,2.18-2.74,2.88-1.15.7-2.46,1.05-3.92,1.05Zm.45-2.46c1.08,0,2.04-.26,2.88-.77.84-.51,1.5-1.21,2-2.1.49-.89.74-1.88.74-2.98s-.25-2.12-.74-3.02c-.49-.89-1.15-1.59-2-2.1-.84-.51-1.8-.76-2.88-.76s-1.99.25-2.85.76c-.86.51-1.54,1.21-2.03,2.1-.49.89-.73,1.9-.73,3.02s.24,2.1.73,2.98c.49.89,1.17,1.59,2.03,2.1.86.51,1.81.77,2.85.77Zm6.9,2.37c-.4,0-.73-.13-.99-.39-.26-.26-.39-.59-.39-.99v-4.8l.57-2.88,2.22.84v6.84c0,.4-.13.73-.41.99-.27.26-.61.39-1,.39Z"/>
        </g>
      </g>
      <path class="cls-6" d="m397.75,243.08c-.4,0-.8-.08-1.19-.25-1.52-.66-2.22-2.43-1.55-3.95.01-.03,1.65-3.9,1.51-7.82-.09-2.34-.93-5.62-1.61-8.26-.34-1.33-.67-2.58-.88-3.65-1.4-6.93.9-13.32,6.32-17.53,1.31-1.02,3.19-.78,4.21.53,1.02,1.31.78,3.19-.53,4.21-4.86,3.77-4.67,8.89-4.12,11.6.18.91.49,2.09.81,3.34.79,3.07,1.69,6.55,1.8,9.54.2,5.32-1.91,10.23-2,10.44-.49,1.13-1.59,1.8-2.75,1.8Z"/>
      <path class="cls-6" d="m411.13,245.46c-.62,0-1.24-.19-1.78-.58-1.33-.98-1.62-2.86-.64-4.19,3.12-4.23,4.64-8.36,4.54-12.27-.06-2.51-.75-4.44-1.48-6.49-.98-2.77-2-5.63-1.3-9.47.78-4.31,3.46-8.19,7.96-11.54,1.33-.99,3.21-.72,4.2.61.99,1.33.72,3.21-.61,4.2-3.24,2.41-5.14,5.04-5.64,7.8-.41,2.26.2,3.99,1.05,6.38.81,2.29,1.73,4.88,1.82,8.35.13,5.28-1.79,10.65-5.71,15.98-.59.8-1.5,1.22-2.42,1.22Z"/>
      <path class="cls-6" d="m424.75,246.84c-.73,0-1.47-.27-2.05-.81-1.21-1.13-1.28-3.03-.15-4.24,3.57-3.82,5.67-7.8,6.26-11.83.31-2.12.16-3.88,0-5.74-.23-2.7-.46-5.5.71-9.1,1.27-3.89,3.77-7.36,7.42-10.31,1.29-1.04,3.18-.84,4.22.45,1.04,1.29.84,3.18-.45,4.22-2.74,2.21-4.58,4.73-5.49,7.5-.8,2.44-.64,4.33-.44,6.73.17,2.02.36,4.3-.05,7.11-.77,5.26-3.4,10.33-7.81,15.06-.59.63-1.39.95-2.19.95Z"/>
      <path class="cls-3" d="m395.71,241.53c-.4,0-.8-.08-1.19-.25-1.52-.66-2.22-2.43-1.55-3.95.01-.03,1.65-3.9,1.51-7.82-.09-2.34-.93-5.62-1.61-8.25-.34-1.33-.67-2.59-.88-3.65-1.4-6.93.9-13.32,6.32-17.53,1.31-1.02,3.19-.78,4.21.53,1.02,1.31.78,3.19-.53,4.21-4.86,3.77-4.67,8.89-4.12,11.6.18.91.49,2.09.81,3.34.79,3.07,1.69,6.54,1.8,9.53.2,5.32-1.91,10.23-2,10.44-.49,1.13-1.59,1.8-2.75,1.8Z"/>
      <path class="cls-3" d="m409.09,243.91c-.62,0-1.24-.19-1.78-.58-1.33-.98-1.62-2.86-.64-4.19,3.12-4.23,4.65-8.36,4.55-12.27-.06-2.51-.75-4.44-1.48-6.49-.98-2.77-2-5.63-1.3-9.47.79-4.31,3.46-8.19,7.96-11.54,1.33-.99,3.21-.72,4.2.61.99,1.33.72,3.21-.61,4.2-3.24,2.41-5.14,5.04-5.64,7.8-.41,2.26.2,3.99,1.05,6.38.81,2.29,1.73,4.88,1.82,8.35.13,5.28-1.79,10.65-5.71,15.98-.59.8-1.5,1.22-2.42,1.22Z"/>
      <path class="cls-3" d="m422.7,245.29c-.73,0-1.47-.27-2.05-.81-1.21-1.13-1.28-3.03-.15-4.24,3.57-3.82,5.67-7.8,6.26-11.83.31-2.12.16-3.88,0-5.74-.23-2.7-.46-5.5.71-9.1,1.27-3.89,3.77-7.36,7.42-10.31,1.29-1.04,3.18-.84,4.22.45,1.04,1.29.84,3.18-.45,4.22-2.73,2.21-4.58,4.73-5.48,7.5-.8,2.44-.64,4.33-.44,6.73.17,2.02.36,4.31-.05,7.11-.77,5.26-3.4,10.33-7.81,15.06-.59.63-1.39.95-2.19.95Z"/>
    </g>
  </g>
      </svg>
    </div>
</template>

<style scoped lang="scss">
.s-coffee-loader {
  .cls-1 {
    fill: #e88b05;
  }

  .cls-2 {
    fill: #ffa305;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #e5e7e6;
  }

  .cls-5 {
    fill: #333a44;
  }

  .cls-6 {
    fill: #8e6354;
  }

  .cls-7 {
    fill: #a69fd6;
  }

  .cls-8 {
    fill: #deaff3;
  }

  .cls-9 {
    fill: #3a4254;
  }

  .cls-10 {
    fill: #495468;
  }

  .cls-11 {
    isolation: isolate;
  }

  .cls-12 {
    opacity: .3;
  }

  .cls-12, .cls-13 {
    mix-blend-mode: multiply;
  }

  .cls-13 {
    opacity: .2;
  }
}

</style>

