
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createPinia } from "pinia";

import App from './App.vue'
import i18n from "./i18n";
import router from './router'
import configureAuth from '@/auth/configureAuth'

import VueTippy from 'vue-tippy'

import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/themes/light.css'

import VCalendar from 'v-calendar';
import './assets/tailwind.css'
import 'v-calendar/style.css';

import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'



const app = createApp(App)
const pinia = createPinia();

app.use(pinia)
app.use(i18n)
app.use(router)
// app.use(VueTippy, {  interactiveDebounce: 350})

app.use(VCalendar, {})
app.use(VueVirtualScroller)

configureAuth()

app.use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
      }, // => Global default options * see all props
    }
  )

router.isReady().then(() => {
  app.mount('#app')
});
