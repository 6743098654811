import { Auth } from 'aws-amplify';

export default function configureAuth() {
  Auth.configure({
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AWS_REGION,
  
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  
    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: 'link', // 'code' | 'link'
  })
}
