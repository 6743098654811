import isEmpty from "lodash.isempty"

export const combinedSessionLocalStorage_getItem = (key) => {
  // If key is initialized in sessionStorage, return it`s value
  if (!isEmpty(sessionStorage.getItem(key))) {
    return sessionStorage.getItem(key)
  }

  // If key is initialized in localStorage
  if (!isEmpty(localStorage.getItem(key))) {
    // Initialize this key in session storage
    sessionStorage.setItem(key, localStorage.getItem(key))
    // Return sessionStorage key value
    return sessionStorage.getItem(key)
  }

  // return undefined if key not in sessionStorage nor localStorage
  return undefined
}

export const clearAll = () => {
  sessionStorage.clear();
  localStorage.clear();
}
