<template>
  <div class="overflow-hidden flex flex-col justify-center">
    <p class="font-semibold	text-sm">{{ user?.username}}</p>
    <p  class="font-light text-xs	"> {{ user?.attributes?.email }}</p>
  </div>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import { Auth } from 'aws-amplify';

const user = ref({});

const currentAuthenticatedUser = async () => {
  try {
    // get authenticated user
    user.value = await Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
  }
  catch (error) {
    console.error(error)
  }
}

onMounted(async ()=> {
    await currentAuthenticatedUser();
})

</script>
