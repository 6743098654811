<template>
    <button
      class="btn btn-ghost transition duration-150 ease-in-out "
      :class="{
        's-card-transparent-5':                                      props.transparent,
        's-card-fill-1   hover:s-card-fill-1  hover:s-text-primary': props.fill,        
        's-text-warning  hover:s-bg-warning   hover:s-text-dark':    props.warning,
        's-text-critical hover:s-bg-critical  hover:s-text-dark':    props.critical,
        'hover:s-card-transparent-5 hover:s-text-primary':           props.ghost,
        'max-w-md': !props.wide,
        'w-full':    props.wide
      }"
      :disabled="props.disabled"
    >
        <!-- icon -->
        <slot></slot>

        <!-- title -->
        <div v-if="props.title"
            :class="{
              'hidden lg:block' : props.hideNameOnSmallScreen,
              'ml-2': $slots.default}">
            {{ props.title }}
        </div>
    </button>
</template>

<script setup>

const props = defineProps({
  title: String,

  hideNameOnSmallScreen: {
    type:    Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  ghost: {
    type: Boolean,
    default: false
  },
  fill: {
    type: Boolean,
    default: false,
  },
  warning: {
    type: Boolean,
    default: false
  },
  critical: {
      type: Boolean,
      default: false
  },
  transparent: {
    type: Boolean,
    default: true
  },
  wide: {
    type: Boolean,
    default: false
  }
})

</script>
