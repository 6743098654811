import {reactive, computed} from 'vue';

const loading = reactive({})

function loadingOn (context) {
    loading[context] = true;

}

function loadingOff (context) {
    if( loading[context]) {
        loading[context] = false;
    }
}

const isLoading = computed(()=> Object.values(loading).some(el=>el))

const loadingHelper = reactive({
    isLoading,
    loadingOn,
    loadingOff,
})

export default loadingHelper;