import { defineStore } from "pinia";

export const useDynamicHeaderStore = defineStore('dynamicHeaderStore', {
	state: () => ({
		previousScrollPosition:0,
		currentScrollPosition: 0,
		headerTopPosition: 0,
		isScrollDown: false,
		headerSize: 80 // cssHeight need to change if design is changed
	}),

	actions: {

		clearAllPositions() {
			this.previousScrollPosition = 0;
			this.currentScrollPosition = 0;
			this.headerTopPosition = 0;
		},

		onMobileScreenScroll(scrollValue) {
			this.currentScrollPosition = scrollValue;
			this.isScrollDown = this.currentScrollPosition > this.previousScrollPosition;


			this.headerTopPosition = this.isScrollDown ?  (this.headerSize * -1) : 0;

			// if(this.isScrollDown) {

			// }

			// const step = this.previousScrollPosition - this.currentScrollPosition ;

			// if(this.headerTopPosition >= (this.headerSize * -1) && this.headerTopPosition <= 0 ) {
			// 	this.headerTopPosition = 0;
				// this.headerTopPosition += step;
				// if(this.headerTopPosition < (this.headerSize * -1)) {
				// 	this.headerTopPosition = (this.headerSize * -1);
				// }
				// if(this.headerTopPosition > 0) {
				// 	this.headerTopPosition = 0;
				// }
			// }
			this.previousScrollPosition = this.currentScrollPosition;
		},
	}
})